<template>
  <div class="c-prekyc">
    <div class="c-prekyc__section" v-show="active == 'contact'">
      <div class="c-prekyc__section-header">
        <div class="c-prekyc__section-step">
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_active">{{'contact' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_address">{{'address' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_employment">{{'employment' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_wealth">{{'wealth' | translate}}</div>
          <div class="c-prekyc__section-stepbar">
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_active"></div>
            <div class="c-prekyc__section-stepbar-line"></div>
            <div class="c-prekyc__section-stepbar-icon"></div>
            <div class="c-prekyc__section-stepbar-line"></div>
            <div class="c-prekyc__section-stepbar-icon"></div>
            <div class="c-prekyc__section-stepbar-line"></div>
            <div class="c-prekyc__section-stepbar-icon"></div>
          </div>
        </div>
      </div>
      <div class="c-prekyc__section-form c-prekyc__section-form_first">
        <div class="c-prekyc__section-form-header">
          <label for="title" class="c-prekyc__section-form-title">{{'contact' | translate}}</label>
        </div>
        <div class="c-prekyc__section-form-container">
          <div class="c-prekyc__section-form-left-field">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'gender' | translate}}</label>
              <div class="c-prekyc__section-form-gender">
                <label class="c-prekyc__section-form-label c-prekyc__section-form-label_gender">
                  {{'male' | translate}}
                  <input checked class = "c-prekyc__section-form-input_gender" type="radio" name="gender" value="male" v-model="inputGender" tabIndex="1"/>
                  <span class="c-prekyc__section-form-input-checkmark"></span>
                </label>
                <label class="c-prekyc__section-form-label c-prekyc__section-form-label_gender">
                  {{'female' | translate}}
                  <input checked class = "c-prekyc__section-form-input_gender" type="radio" name="gender" value="female" v-model="inputGender" tabIndex="2"/>
                  <span class="c-prekyc__section-form-input-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-left-field">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'first_name' | translate}}</label>
              <div class="c-prekyc__section-form-text">
                <input class="c-prekyc__section-form-text-input" type="text" @keypress="onlyText" name="first-name" :placeholder="translate('first_name').toLowerCase()" v-model="inputFirstName" autocomplete="new-password" tabIndex="3"/>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-right-field">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'last_name' | translate}}</label>
              <div class="c-prekyc__section-form-text">
                <input class="c-prekyc__section-form-text-input" type="text" @keypress="onlyText" name="last-name" :placeholder="translate('last_name').toLowerCase()" v-model="inputLastName" autocomplete="new-password" tabIndex="4"/>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-left-field">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label" v-html="translate('alias_if_any')"></label>
              <div class="c-prekyc__section-form-text">
                <input class="c-prekyc__section-form-text-input" type="text" @keypress="onlyText" name="alias" :placeholder="translate('alias').toLowerCase()" v-model="inputAlias" autocomplete="new-password" tabIndex="5"/>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-right-field">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'dob' | translate}}</label>
              <div class="datepicker-container">
                <Datepicker v-model="inputDateOfBirth" :disabled-dates="disabledDates" :open-date = "disabledDates.from" :placeholder="translate('dob').toLowerCase()" input-class="datepicker-inputclass" calendar-class="datepicker-calendarclass" format="dd MMMM yyyy" :calendar-button="true" calendar-button-icon="el-icon-date" tabIndex="6"></Datepicker>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-left-field">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'nationality' | translate}}</label>
              <div class="c-prekyc__section-form-option">
                <Select2 v-model="selectedNationality" :options="dropdown.dropdownNationality" :settings="{'width':'100%', 'placeholder': translate('nationality').toLowerCase()}" :key="translate('nationality').toLowerCase()" tabIndex="7"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-prekyc__section-bottom">
        <button class="c-prekyc__section-bottom-button" @click="submitContact()" :disabled="!checkContact">
          <i class="ball-pulse-small" v-if="preloader">
            <div></div>
            <div></div>
            <div></div>
          </i>
          <span :class="{'c-prekyc__section-bottom-button_disabled':!checkContact}" v-else>{{"next" | translate}}</span>
        </button>
        <a class="c-prekyc__section-bottom-skip" @click="skipToDashboard()">{{"skip" | translate}}<i class="el-icon-d-arrow-right c-prekyc__section-bottom-icon"></i></a>
      </div>
    </div>
    <div class="c-prekyc__section" v-show="active == 'address'">
      <div class="c-prekyc__section-header">
        <img class="c-prekyc__section-header-back" src = "@/assets/images/back.svg" @click="goTo('contact')">
        <div class="c-prekyc__section-step">
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_passed" @click="goTo('contact')">{{'contact' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_address c-prekyc__section-step-label_active">{{'address' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_employment">{{'employment' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_wealth">{{'wealth' | translate}}</div>
          <div class="c-prekyc__section-stepbar">
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_passed" @click="setActive('contact')"></div>
            <div class="c-prekyc__section-stepbar-line c-prekyc__section-stepbar-line_active"></div>
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_active"></div>
            <div class="c-prekyc__section-stepbar-line"></div>
            <div class="c-prekyc__section-stepbar-icon"></div>
            <div class="c-prekyc__section-stepbar-line"></div>
            <div class="c-prekyc__section-stepbar-icon"></div>
          </div>
        </div>
      </div>
      <div class="c-prekyc__section-form c-prekyc__section-form_first">
        <div class="c-prekyc__section-form-header">
          <label for="title" class="c-prekyc__section-form-title">{{'address' | translate}}</label>
        </div>
        <div class="c-prekyc__section-form-container">
          <div class="c-prekyc__section-form-full-field">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'address' | translate}}</label>
              <div class="c-prekyc__section-form-text">
                <span id="input-address" class="c-prekyc__section-form-textarea" role="textbox" :title="translate('address')" @input="getAddress" contenteditable tabIndex="7"></span>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-third">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'postal_code' | translate}}</label>
              <div class="c-prekyc__section-form-text">
                <input class="c-prekyc__section-form-text-input" type="text" @keypress="onlyNumber" name="postal-code" :placeholder="translate('postal_code').toLowerCase()" v-model="inputPostalCode" autocomplete="new-password" tabIndex="8"/>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-third c-prekyc__section-form-third_middle">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'city' | translate}}</label>
              <div class="c-prekyc__section-form-text">
                <input class="c-prekyc__section-form-text-input" type="text" @keypress="onlyText" name="city" :placeholder="translate('city').toLowerCase()" v-model="inputCity" autocomplete="new-password" tabIndex="9"/>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-third">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'country' | translate}}</label>
              <div class="c-prekyc__section-form-option">
                <Select2 v-model="selectedCountry" :options="dropdown.dropdownCountry" :settings="{'width':'100%', 'placeholder': translate('country').toLowerCase()}" :key="translate('country').toLowerCase()" tabIndex="10"/>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-full-field">
            <p class="c-prekyc__section-form-subtitle">{{'proof_of_residence' | translate}}</p>
            <p class="c-prekyc__section-form-description" v-html="translate('in_order_for_sparrow')">
            </p>
            <p class="c-prekyc__section-form-list">
              • <span>{{'service_provider' | translate}}</span><br>
              • <span>{{'correspondence_letter' | translate}}</span><br>
              • <span>{{'existing_lease' | translate}}</span>
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'note' | translate}}</span><br>
              {{'all_documents' | translate}}
            </p>
          </div>
          <div class = "c-prekyc__section-form-address-proof">
            <div class="dropzones">
              <vue-dropzone class="dropzones__upload" id="dropzone-statement" :options="dropOptions" :useCustomSlot="true" @vdropzone-file-added="addProofResidenceFile" v-if="typeof proofResidence.name != 'string' " ref = "dropzone" tabindex="11">
                <div class = "dz-message">
                  <div class="dz-icon-div">
                    <div class="rel">
                      <img class="dz-icon-img" src = "@/assets/images/up-img-kyc.svg">
                    </div>
                  </div>
                  <div class = "dz-message">
                    <div class = "droptext" v-html="translate('drop_file_new')"></div>
                  </div>
                </div>
              </vue-dropzone>
              <div v-else>
                <div class="uploaded-statement dropzones__file" v-bind:class="{'dropzones__file__failed':proofResidenceFailed}">
                  <input class="file-statement dropzones__file__name" type="text" :value="proofResidence.name" disabled />
                  <i class="el-icon-close dropzones__file__close" @click="closeProofFile()"></i>
                </div>
                <p class="dropzones__failed" v-if="proofResidenceFailed">{{'make_sure_upload' | translate}}</p>
                <p class="dropzones__failed" v-else-if="fileSizeTooBig">{{'upload_less_than' | translate}}{{maxUploadSizeInMB}}MB.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-prekyc__section-bottom">
        <button class="c-prekyc__section-bottom-button" @click="submitAddress()" :disabled="!checkAddress">
          <i class="ball-pulse-small" v-if="preloader">
            <div></div>
            <div></div>
            <div></div>
          </i>
          <span :class="{'c-prekyc__section-bottom-button_disabled':!checkAddress}" v-else>{{"next" | translate}}</span>
        </button>
        <a class="c-prekyc__section-bottom-skip" @click="skipToDashboard()">{{"skip" | translate}}<i class="el-icon-d-arrow-right c-prekyc__section-bottom-icon"></i></a>
      </div>
    </div>
    <div class="c-prekyc__section" v-show="active == 'employment'">
      <div class="c-prekyc__section-header">
        <img class="c-prekyc__section-header-back" src = "@/assets/images/back.svg" @click="goTo('address')">
        <div class="c-prekyc__section-step">
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_passed">{{'contact' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_address c-prekyc__section-step-label_passed">{{'address' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_employment c-prekyc__section-step-label_active">{{'employment' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_wealth">{{'wealth' | translate}}</div>
          <div class="c-prekyc__section-stepbar">
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_passed"></div>
            <div class="c-prekyc__section-stepbar-line c-prekyc__section-stepbar-line_active"></div>
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_passed"></div>
            <div class="c-prekyc__section-stepbar-line c-prekyc__section-stepbar-line_active"></div>
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_active"></div>
            <div class="c-prekyc__section-stepbar-line"></div>
            <div class="c-prekyc__section-stepbar-icon"></div>
          </div>
        </div>
      </div>
      <div class="c-prekyc__section-form c-prekyc__section-form_first">
        <div class="c-prekyc__section-form-header">
          <label for="title" class="c-prekyc__section-form-title">{{'address' | translate}}</label>
        </div>
        <div class="c-prekyc__section-form-container">
          <div class="c-prekyc__section-form-employment">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'employment_status' | translate}}</label>
              <div class="c-prekyc__section-form-option">
                <Select2 v-model="selectedEmStatus" :options="dropdown.dropdownEmploymentStatus" :settings="{'width':'100%', 'placeholder': translate('employment_status').toLowerCase()}" :key="translate('employment_status').toLowerCase()" tabIndex="10"/>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-employment">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'annual_income_range' | translate}}</label>
              <div class="c-prekyc__section-form-option">
                <Select2 v-model="selectedAnnualIncome" :options="dropdown.dropdownAnnualIncome" :settings="{'width':'100%', 'placeholder': translate('annual_income_range').toLowerCase()}" :key="translate('annual_income_range').toLowerCase()" tabIndex="10"/>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-employment" v-if="checkEmploymentSubMenu">
            <div class="c-prekyc__section-form-input-set">
              <label class="c-prekyc__section-form-label">{{'nature_of_business' | translate}}</label>
              <div class="c-prekyc__section-form-option">
                <Select2 v-model="selectedNatureBussiness" :options="dropdown.dropdownNatureBusiness" :settings="{'width':'100%', 'placeholder': translate('nature_of_business').toLowerCase()}" :key="translate('nature_of_business').toLowerCase()" tabIndex="10"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-prekyc__section-bottom">
        <button class="c-prekyc__section-bottom-button" @click="submitEmployment()" :disabled="!checkEmployment">
          <i class="ball-pulse-small" v-if="preloader">
            <div></div>
            <div></div>
            <div></div>
          </i>
          <span :class="{'c-prekyc__section-bottom-button_disabled':!checkEmployment}" v-else>{{"next" | translate}}</span>
        </button>
        <a class="c-prekyc__section-bottom-skip" @click="skipToDashboard()">{{"skip" | translate}}<i class="el-icon-d-arrow-right c-prekyc__section-bottom-icon"></i></a>
      </div>
    </div>
    <div class="c-prekyc__section" v-show="active == 'wealth'">
      <div class="c-prekyc__section-header">
        <img class="c-prekyc__section-header-back" src = "@/assets/images/back.svg" @click="goTo('employment')">
        <div class="c-prekyc__section-step">
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_passed" @click="setActive('contact')">{{'contact' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_address c-prekyc__section-step-label_passed" @click="setActive('address')">{{'address' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_employment c-prekyc__section-step-label_passed" @click="setActive('employment')">{{'employment' | translate}}</div>
          <div class="c-prekyc__section-step-label c-prekyc__section-step-label_wealth c-prekyc__section-step-label_active">{{'wealth' | translate}}</div>
          <div class="c-prekyc__section-stepbar">
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_passed"></div>
            <div class="c-prekyc__section-stepbar-line c-prekyc__section-stepbar-line_active"></div>
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_passed"></div>
            <div class="c-prekyc__section-stepbar-line c-prekyc__section-stepbar-line_active"></div>
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_passed"></div>
            <div class="c-prekyc__section-stepbar-line c-prekyc__section-stepbar-line_active"></div>
            <div class="c-prekyc__section-stepbar-icon c-prekyc__section-stepbar-icon_active"></div>
          </div>
        </div>
      </div>
      <div class="c-prekyc__section-form c-prekyc__section-form_first">
        <div class="c-prekyc__section-form-header">
          <label for="title" class="c-prekyc__section-form-title">{{'wealth' | translate}}</label>
        </div>
        <div class="c-prekyc__section-form-container">
          <div class="c-prekyc__section-form-full-field">
            <p class="c-prekyc__section-form-subtitle">{{'source_of_funds' | translate}}</p>
            <p class="c-prekyc__section-form-description">{{'source_of_funds_desc' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'digital_payment_tokens' | translate}} </span>
              {{'refers_to_any_cryptographically' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'note' | translate}}</span><br>
              {{'source_of_funds_not_same' | translate}}
            </p>
          </div>
          <div class="c-prekyc__section-form-full-field">
            <div class="c-prekyc__section-form-input-set">
              <div class="c-prekyc__section-form-option c-prekyc__section-form-option_top">
                <Select2 v-model="selectedSourceFunds" :options="dropdown.dropdownSourceFunds" :settings="{'width':'100%', 'placeholder': translate('source_of_funds').toLowerCase()}" :key="translate('source_of_funds').toLowerCase()" tabIndex="15"/>
              </div>
            </div>
            <div class="c-prekyc__section-form-input-set" v-if="otherSourceFunds">
              <label class="c-prekyc__section-form-label">{{'your_source_of_funds' | translate}}</label>
              <div class="c-prekyc__section-form-text">
                <input class="c-prekyc__section-form-text-input" type="text" name="sourceOfFund" :placeholder="translate('your_source_of_funds').toLowerCase()" v-model="otherSourceOfFunds" autocomplete="new-password" tabIndex="16"/>
              </div>
            </div>
          </div>
          <div class="c-prekyc__section-form-full-field">
            <p class="c-prekyc__section-form-subtitle">{{'source_of_wealth' | translate}}</p>
            <p class="c-prekyc__section-form-description">{{'source_of_wealth_desc' | translate}}
            </p>
          </div>
          <div class="c-prekyc__section-form-full-field">
            <div class="c-prekyc__section-form-input-set">
              <div class="c-prekyc__section-form-option c-prekyc__section-form-option_top">
                <Select2 v-model="selectedSourceWealth" :options="dropdown.dropdownSourceWealth" :settings="{'width':'100%', 'placeholder': translate('source_of_wealth').toLowerCase()}" :key="translate('source_of_wealth').toLowerCase()" tabIndex="17"/>
              </div>
            </div>
            <div class="c-prekyc__section-form-input-set" v-if="otherSourceWealth">
              <label class="c-prekyc__section-form-label">{{'your_source_of_wealth' | translate}}</label>
              <div class="c-prekyc__section-form-text">
                <input class="c-prekyc__section-form-text-input" type="text" name="sourceOfWealth" :placeholder="translate('your_source_of_wealth').toLowerCase()" v-model="otherSourceOfWealth" autocomplete="new-password" tabIndex="17"/>
              </div>
            </div>
          </div>
          <div class = "c-prekyc__section-form-full-field">
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-if="selectedSourceWealth == 'Saving from Salary'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'latest_copy_payslip' | translate}}<br>
              • {{'letter_employer_confirming' | translate}}<br>
              • {{'latest_audited_accounts' | translate}}<br>
              • {{'latest_tax_declaration' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-else-if="selectedSourceWealth == 'Sale of property'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'proof_property_sale' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-else-if="selectedSourceWealth == 'Sales of shares or other investments'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'saving_certificates' | translate}}<br>
              • {{'bank_statement_clearly' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-else-if="selectedSourceWealth == 'Inheritance'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'grant_probate' | translate}}<br>
              • {{'title_deed' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-else-if="selectedSourceWealth == 'Business gain from a company sale'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'copy_contract_sale' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-else-if="selectedSourceWealth == 'Company profit from ownership of a business'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'copy_latest_financial' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-if="selectedSourceWealth == 'Maturing investment or policy claim'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'letter_investment' | translate}}<br>
              • {{'changeable_event' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-if="selectedSourceWealth == 'Lottery / Betting / Casino win'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'letter_lottery' | translate}}<br>
              • {{'bank_funds' | translate}}<br>
              • {{'copy_media' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-if="selectedSourceWealth == 'Retirement income'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'pension_statement' | translate}}<br>
              • {{'letter_accountant' | translate}}<br>
              • {{'bank_statement' | translate}}<br>
              • {{'saving_statement' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-if="selectedSourceWealth == 'Gift / Donation'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'letter_donor' | translate}}<br>
              • {{'certified_donor' | translate}}<br>
              • {{'suitable_documentation' | translate}}
            </p>
            <p class="c-prekyc__section-form-description c-prekyc__section-form-description_note" v-if="selectedSourceWealth == 'Others'">
              <span class="c-prekyc__section-form-description c-prekyc__section-form-description_bold">{{'document_submission' | translate}}</span><br>
              • {{'signed_document' | translate}}
            </p>
          </div>
          <div class = "c-prekyc__section-form-address-proof">
            <div class="dropzones" v-if="selectedSourceWealth">
              <vue-dropzone class="dropzones__upload" id="dropzone-statement" :options="dropOptions" :useCustomSlot="true" @vdropzone-file-added="addSubmissionFile" v-if="typeof submission.name != 'string' " ref = "dropzone" tabindex="18">
                <div class = "dz-message">
                  <div class="dz-icon-div">
                    <div class="rel">
                      <img class="dz-icon-img" src = "@/assets/images/up-img-kyc.svg">
                    </div>
                  </div>
                  <div class = "dz-message">
                    <div class = "droptext" v-html="translate('drop_file_new')"></div>
                  </div>
                </div>
              </vue-dropzone>
              <div v-else>
                <div class="uploaded-statement dropzones__file" v-bind:class="{'dropzones__file__failed':submissionFailed}">
                  <input class="file-statement dropzones__file__name" type="text" :value="submission.name" disabled />
                  <i class="el-icon-close dropzones__file__close" @click="closeSubmissionFile()"></i>
                </div>
                <p class="dropzones__failed" v-if="submissionFailed">{{'make_sure_upload' | translate}}</p>
                <p class="dropzones__failed" v-else-if="submissionFileSizeTooBig">{{'upload_less_than' | translate}}{{maxUploadSizeInMB}}MB.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-prekyc__section-bottom">
        <button class="c-prekyc__section-bottom-button" @click="formSubmit()" :disabled="!checkWealth">
          <i class="ball-pulse-small" v-if="preloader">
            <div></div>
            <div></div>
            <div></div>
          </i>
          <span :class="{'c-prekyc__section-bottom-button_disabled':!checkWealth}" v-else>
            <span v-if="kycStatus=='VERIFIED' || kycStatus=='PENDING_REVIEW'">{{"submit" | translate}}</span>
            <span v-else>{{"next" | translate}}</span>
          </span>
        </button>
        <a class="c-prekyc__section-bottom-skip" @click="skipToDashboard()">{{"skip" | translate}}<i class="el-icon-d-arrow-right c-prekyc__section-bottom-icon"></i></a>
      </div>
    </div>
    <a class="c-prekyc__help" @click="showQuickGuide()">{{"kyc_tips_trigger" | translate}}</a>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import Datepicker from 'vuejs-datepicker';
import $ from 'jquery'
const getTemplate = () => `<div class="dz-preview dz-file-preview"></div>`;
export default {
  name: 'PreKYCForm',
  components: {
    Datepicker,
    vueDropzone
  },
  data() {
    return {
      active: "contact",
      preloader: false,
      inputGender: "",
      inputFirstName: "",
      inputLastName: "",
      inputAlias: "",
      inputDateOfBirth: "",
      selectedNationality: "",
      nationalityCode: "SGP",
      inputAddress: "",
      isInputAddressEmpty: true,
      inputPostalCode: "",
      inputCity: "",
      selectedCountry: "",
      countryCode: "SGP",
      selectedEmStatus: "",
      selectedAnnualIncome: "",
      selectedNatureBussiness: "",
      selectedAnnualValue:"",
      proofFileSizeRes: 0,
      proofStatusUpload: false,
      proofResidence: {},
      proofResidenceFailed: false,
      residenceUploadedSuccess: false,
      selectedSourceFunds: "",
      selectedSourceWealth: "",
      otherSourceOfFunds: "",
      otherSourceOfWealth: "",
      submissionStatusUpload: false,
      submission: {},
      submissionFailed: false,
      submissionUploadedSuccess: false,
      disabledDates: {
        from: new Date(2016, 0, 26)
      },
      countryList: [],
      nationalityList: [],
      employmentStatus: [],
      natureBussinessList: [],
      annualIncomeList: [],
      sourceWealthList: [],
      sourceFundsList: [],
      dropdown: {
        dropdownCountry: [],
        dropdownNationality: [],
        dropdownEmploymentStatus: [],
        dropdownSourceFunds: [],
        dropdownNatureBusiness: [],
        dropdownAnnualIncome: [],
        dropdownSourceWealth: []
      },
      dropOptions: {
        url: () => {
          // console.log(files)
        },
        acceptedFiles: ".jpg, .jpeg, .png, .pdf",
        autoQueue: false,
        maxFiles: 1,
        previewTemplate: getTemplate(),
      },
      preKYCDraftData:{}
    }
  },
  mounted:async function(){
    this.fetchNationalityData();
    await this.fetchEmploymentData();
    await this.fetchNatureData();
    await this.fetchAnnualIncome();
    this.fetchSourceFunds();
    this.fetchSourceWealth();
    this.disable18Yo()
    this.fetchProfileData();
  },
  methods:{
    goTo(param) {
      this.active = param
    },
    showQuickGuide:function(){
      this.$eventHub.$emit('show-quick-guide')
    },
    skipToDashboard:function(){
      window.location = '/dashboard/#/checker'
    },
    disable18Yo() {
      let today = new Date();
      let date = today.getDate();
      let month = today.getMonth();
      let year = today.getFullYear() - 18
      this.disabledDates.from = new Date(year, month, date)
    },
    getAddress() {
      this.inputAddress = $('#input-address').text()
    },
    onlyText($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode > 43 && keyCode < 58)) {
        $event.preventDefault();
      }
    },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode > 64 && keyCode < 91) || (keyCode > 96 && keyCode < 123) || keyCode == 32) {
        $event.preventDefault();
      }
    },
    getTranslatedEmployment:function(){
      var _this = this;
      this.employmentStatus.forEach(function(e){
        if(e.value==_this.selectedEmValue){
          e.selected = 1;
          _this.selectedEmStatus = e.name;
        }
      })
    },
    getTranslationNature:function(){
      var _this = this;
      this.natureBussinessList.forEach(function(e){
        if(e.value==_this.selectedNatureValue){
          e.selected = 1;
          _this.selectedNatureBussiness = e.name;
        }
      })
    },
    getTranslationAnnualIncome:function(){
      var _this = this;
      this.annualIncomeList.forEach(function(e){
        if(e.value==_this.selectedAnnualValue){
          e.selected = 1;
          _this.selectedAnnualIncome = e.name;
        }
      })
    },
    getTranslationSourceFunds:function(){
      var _this = this;
      this.sourceFundsList.forEach(function(e){
        if(e.value==_this.selectedSourceFunds){
          e.selected = 1;
          _this.selectedSourceFunds = e.name;
        }
      })
    },
    getTranslationSourceWealth:function(){
      var _this = this;
      this.sourceWealthList.forEach(function(e){
        if(e.value==_this.selectedSourceWealth){
          e.selected = 1;
          _this.selectedSourceWealth = e.name;
        }
      })
    },
    fetchNationalityData:function(){
      var temp = [];
      this.$http.get(this.createApiUrlChestNut("system/supported-countries")).then(response => {
        response.body.forEach(function(res){
          var country = {"name":"", "selected":0, "code": ""};
          country.name = res.country;
          country.code = res.code;
          temp.push(country);
        })
        this.nationalityList = temp;
        this.countryList = temp;
        this.dropdown.dropdownNationality = []
        this.dropdown.dropdownCountry = []
        this.nationalityList.forEach(element => {
          this.dropdown.dropdownNationality.push(element.name)
          this.dropdown.dropdownCountry.push(element.name)
        })
      },err => {
        this.showMessageError(err.body.message)
      })
    },
    fetchEmploymentData:function(){
      var temp = [];
      this.$http.get(this.createApiUrlChestNut("system/kyc-employment-status?locale="+this.kycLanguage)).then(response => {
        response.body.forEach(function(res){
          var emp = {"name":"", "selected":0, "submenu":false, "value":""};
          emp.name = res.name;
          emp.submenu = res.submenu;
          emp.value = res.value;
          temp.push(emp);
        })
        this.employmentStatus = temp;
        this.dropdown.dropdownEmploymentStatus = []
        this.employmentStatus.forEach(element => {
          this.dropdown.dropdownEmploymentStatus.push(element.name)
        })
        if(this.selectedEmValue!=''){
          this.getTranslatedEmployment();
        }
      },err => {
        this.showMessageError(err.body.message)
      })
    },
    fetchNatureData: function(){
      var temp = [];
      this.$http.get(this.createApiUrlChestNut("system/kyc-nature-of-business?locale="+this.kycLanguage)).then(response => {
        response.body.forEach(function(res){
          var emp = {"name":"", "selected":0, "value":""};
          emp.name = res.name;
          emp.value = res.value;
          temp.push(emp);
        })
        this.natureBussinessList = temp;
        this.dropdown.dropdownNatureBusiness = []
        this.natureBussinessList.forEach(element => {
          this.dropdown.dropdownNatureBusiness.push(element.name)
        })
        if(this.selectedNatureBussiness!=''){
          this.getTranslationNature();
        }
      },err => {
        this.showMessageError(err.body.message)
      })
    },
    fetchAnnualIncome: function(){
      var temp = [];
      this.$http.get(this.createApiUrlChestNut("system/kyc-range-of-annual-income?locale="+this.kycLanguage)).then(response => {
        response.body.forEach(function(res){
          var emp = {"name":"", "selected":0, "value":""};
          emp.name = res.name;
          emp.value = res.value;
          temp.push(emp);
        })
        this.annualIncomeList = temp;
        this.dropdown.dropdownAnnualIncome = []
        this.annualIncomeList.forEach(element => {
          this.dropdown.dropdownAnnualIncome.push(element.name)
        })
        if(this.selectedAnnualIncome!=''){
          this.getTranslationAnnualIncome();
        }
      },err => {
        this.showMessageError(err.body.message)
      })
    },
    fetchSourceFunds: function(){
      var temp = [];
      this.$http.get(this.createApiUrlChestNut("system/kyc-source-of-fund?locale="+this.kycLanguage)).then(response => {
        response.body.forEach(function(res){
          var emp = {"name":"", "selected":0, "submenu":false, "value":""};
          emp.name = res.name;
          emp.submenu = res.submenu;
          emp.value = res.value;
          temp.push(emp);
        })
        this.sourceFundsList = temp;
        this.dropdown.dropdownSourceFunds = []
        this.sourceFundsList.forEach(element => {
          this.dropdown.dropdownSourceFunds.push(element.name)
        })
        if(this.selectedSourceFunds!=''){
          this.getTranslationSourceFunds();
        }
      },err => {
        this.showMessageError(err.body.message)
      })
    },
    fetchSourceWealth: function(){
      var temp = [];
      this.$http.get(this.createApiUrlChestNut("system/kyc-source-of-wealth?locale="+this.kycLanguage)).then(response => {
        response.body.forEach(function(res){
          var emp = {"name":"", "selected":0, "value":""};
          emp.name = res.name;
          emp.value = res.value;
          temp.push(emp);
        })
        this.sourceWealthList = temp;
        this.dropdown.dropdownSourceWealth = []
        this.sourceWealthList.forEach(element => {
          this.dropdown.dropdownSourceWealth.push(element.name)
        })
        if(this.selectedSourceWealth!=''){
          this.getTranslationSourceWealth();
        }
      },err => {
        this.showMessageError(err.body.message)
      })
    },
    fetchProfileData:function(){
      this.$http.get(this.createApiUrlChestNut("users/draft-prekyc")).then(response => {
        if(response.body){
          this.processDraftData(response.body);
        }
      }, err => {
        this.showMessageError(err.body.message)
      })
    },
    closeProofFile: function () {
      this.proofStatusUpload = false
      this.proofResidence = {},
      this.residenceUploadedSuccess = false;
    },
    closeSubmissionFile: function () {
      this.submissionStatusUpload = false
      this.submission = {},
      this.submissionUploadedSuccess = false;
    },
    addProofResidenceFile: function (file) {
      this.proofStatusUpload = false
      this.proofResidence = file;
      this.proofFileSizeRes = file.size
      if (file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf") {
        this.residenceUploadedSuccess = true
        this.proofResidenceFailed = false
      } else {
        this.residenceUploadedSuccess = false
        this.proofResidenceFailed = true
      }
    },
    addSubmissionFile: function (file) {
      this.submissionStatusUpload = false
      this.submission = file;
      if (file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf") {
        this.submissionUploadedSuccess = true
        this.submissionFailed = false
      } else {
        this.submissionUploadedSuccess = false
        this.submissionFailed = true
      }
      this.subFileSizeRes = file.size
    },
    formSubmit: function () {
      if (this.preloader) {
        return false
      }
      this.uploadResidenceFile();
      this.preloader = true
    },
    uploadResidenceFile: function () {
      let _this = this
      let file = this.proofResidence
      let _token = this.$store.getters.token
      let _url = this.createApiUrlChestNut('statement')
      $.ajax({
        url: _url,
        method: "POST",
        headers: {
          "Content-Type": file.type,
          "Authorization": "Bearer " + _token,
          "cache-control": "no-cache"
        },
        processData: false,
        data: file,
        success: function () {
          _this.uploadSubmissionFile();
        },
        error: function () {
          _this.setActive('address');
          _this.preloader = false;
          _this.closeProofFile();
          _this.residenceUploadedSuccess = false
        }
      })
    },
    uploadSubmissionFile: function () {
      let _this = this
      let file = this.submission
      let _token = this.$store.getters.token
      let _url = this.createApiUrlChestNut('source-of-wealth')
      $.ajax({
        url: _url,
        method: "POST",
        headers: {
          "Content-Type": file.type,
          "Authorization": "Bearer " + _token,
          "cache-control": "no-cache"
        },
        processData: false,
        data: file,
        success: function () {
          _this.formPreKYCSubmit();
        },
        error: function () {
          _this.setActive('wealth');
          _this.preloader = false;
          _this.closeSubmissionFile();
          _this.submissionUploadedSuccess = false
        }
      })
    },
    submitContact: function(){
      if(this.preloader){
        return false;
      }
      this.preloader = true;
      var fullName = this.inputFirstName + " " + this.inputLastName;
      var formData = this.preKYCDraftData;
      formData.fullName = fullName
      formData.alias = this.inputAlias
      formData.gender = this.inputGender
      formData.dateOfBirth = this.$moment(this.inputDateOfBirth).format('MM/DD/YYYY')
      formData.nationality = this.nationalityCode
      formData.draftPreKYC = true
      this.$http.put(this.createApiUrlChestNut("users/draft-prekyc"), formData).then(() => {
        this.goTo('address')
        this.preloader = false
      }, err => {
        this.showMessageError(err.body.message)
        this.preloader = false
      })
    },
    submitAddress: function(){
      if(this.preloader){
        return false;
      }
      this.preloader = true;
      var fullName = this.inputFirstName + " " + this.inputLastName;
      var formData = this.preKYCDraftData;
      formData.fullName = fullName
      formData.alias = this.inputAlias
      formData.gender = this.inputGender
      formData.dateOfBirth = this.$moment(this.inputDateOfBirth).format('MM/DD/YYYY')
      formData.nationality = this.nationalityCode
      formData.residentialAddress = this.inputAddress
      formData.city = this.inputCity
      formData.state = this.selectedCountry
      formData.postalCode = this.inputPostalCode
      formData.residentialCountry = this.countryCode
      formData.draftPreKYC = true
      this.$http.put(this.createApiUrlChestNut("users/draft-prekyc"), formData).then(() => {
        this.goTo('employment')
        this.preloader = false
      }, err => {
        this.showMessageError(err.body.message)
        this.preloader = false
      })
    },
    submitEmployment() {
      if(this.preloader){
        return false;
      }
      this.preloader = true;
      var fullName = this.inputFirstName + " " + this.inputLastName;
      var formData = this.preKYCDraftData;
      formData.fullName = fullName
      formData.alias = this.inputAlias
      formData.gender = this.inputGender
      formData.dateOfBirth = this.$moment(this.inputDateOfBirth).format('MM/DD/YYYY')
      formData.nationality = this.nationalityCode
      formData.residentialAddress = this.inputAddress
      formData.city = this.inputCity
      formData.state = this.selectedCountry
      formData.postalCode = this.inputPostalCode
      formData.residentialCountry = this.countryCode
      formData.employmentStatus = this.selectedEmValue
      formData.natureOfBusiness = this.selectedNatureValue
      formData.rangeOfAnnualIncome = this.selectedAnnualValue
      formData.draftPreKYC = true
      this.$http.put(this.createApiUrlChestNut("users/draft-prekyc"), formData).then(() => {
        this.goTo('wealth')
        this.preloader = false
      }, err => {
        this.showMessageError(err.body.message)
        this.preloader = false
      })
    },
    submitCompleteDraft: function(){
      var fullName = this.inputFirstName + " " + this.inputLastName;
      var sourceoffunds = ""
      var sourceofwealth = ""
      if (this.selectedSourceFunds == "Others") {
        sourceoffunds = this.otherSourceOfFunds
      }
      if (this.selectedSourceWealth == "Others") {
        sourceofwealth = this.otherSourceOfWealth
      }
      var formData = {
        fullName: fullName,
        alias: this.inputAlias,
        gender: this.inputGender,
        residentialAddress: this.inputAddress,
        city: this.inputCity,
        state: this.selectedCountry,
        postalCode: this.inputPostalCode,
        residentialCountry: this.countryCode,
        employmentStatus: this.selectedEmValue,
        natureOfBusiness: this.selectedNatureValue,
        dateOfBirth: this.$moment(this.inputDateOfBirth).format('MM/DD/YYYY'),
        sourceOfFund: this.selectedSourceFunds,
        sourceOfFundDescription: sourceoffunds,
        rangeOfAnnualIncome: this.selectedAnnualValue,
        sourceOfWealth: this.selectedSourceWealth,
        sourceOfWealthDescription: sourceofwealth,
        nationality: this.nationalityCode,
        draftPreKYC: true
      }
      if (this.selectedEmSubmenu) {
        formData.natureOfBusiness = this.selectedNatureValue
      }
      this.$http.put(this.createApiUrlChestNut("users/draft-prekyc"), formData).then(() => {
      }, err => {
        this.showMessageError(err.body.message)
      })
    },
    formPreKYCSubmit: function () {
      this.submitCompleteDraft()
      var fullName = this.inputFirstName + " " + this.inputLastName;
      var sourceoffunds = ""
      var sourceofwealth = ""
      if (this.selectedSourceFunds == "Others") {
        sourceoffunds = this.otherSourceOfFunds
      }
      if (this.selectedSourceWealth == "Others") {
        sourceofwealth = this.otherSourceOfWealth
      }
      var formData = {
        fullName: fullName,
        alias: this.inputAlias,
        gender: this.inputGender,
        residentialAddress: this.inputAddress,
        city: this.inputCity,
        state: this.selectedCountry,
        postalCode: this.inputPostalCode,
        residentialCountry: this.countryCode,
        employmentStatus: this.selectedEmValue,
        natureOfBusiness: this.selectedNatureValue,
        dateOfBirth: this.$moment(this.inputDateOfBirth).format('MM/DD/YYYY'),
        sourceOfFund: this.selectedSourceFunds,
        sourceOfFundDescription: sourceoffunds,
        rangeOfAnnualIncome: this.selectedAnnualValue,
        sourceOfWealth: this.selectedSourceWealth,
        sourceOfWealthDescription: sourceofwealth,
        nationality: this.nationalityCode,
        draftPreKYC: false
      }
      if (this.selectedEmSubmenu) {
        formData.natureOfBusiness = this.selectedNatureValue
      }
      this.$http.put(this.createApiUrlChestNut("me"), formData).then(() => {
        this.showNotifSuccess(this.translate('form_submitted'), this.translate('success'))
        this.$eventHub.$emit('formSubmitted');
        this.preloader = false
      }, err => {
        this.showMessageError(err.body.message)
        this.preloader = false
      })
    },
    processDraftData:function(draft){
      let _this = this;
      this.preKYCDraftData = draft;
      //fill contact
      this.inputFirstName = this.preKYCDraftData.firstName;
      this.inputLastName = this.preKYCDraftData.lastName;
      this.inputAlias = this.preKYCDraftData.alias;
      this.inputGender = this.preKYCDraftData.gender.toLowerCase();
      this.inputDateOfBirth = this.preKYCDraftData.dateOfBirth;
      this.nationalityCode = this.preKYCDraftData.nationality;
      this.nationalityList.forEach(element => {
        if (this.nationalityCode === element.code) {
          this.selectedNationality = element.name
        }
      })
      //fill address
      this.inputAddress = this.preKYCDraftData.residentialAddress;
      $('#input-address').text(this.inputAddress)
      this.inputPostalCode = this.preKYCDraftData.postalCode;
      this.inputCity = this.preKYCDraftData.city;
      this.selectedCountry = this.preKYCDraftData.state;
      this.countryCode = this.preKYCDraftData.residentialCountry;
      //fill employment
      this.selectedEmValue = this.preKYCDraftData.employment;
      this.employmentStatus.forEach(element => {
        if (this.selectedEmValue === element.value) {
          this.selectedEmStatus = element.name
          this.selectedEmSubmenu = element.submenu
        }
      })
      this.selectedNatureValue = this.preKYCDraftData.natureOfBiz;
      this.natureBussinessList.forEach(element => {
        if (this.selectedNatureValue === element.value) {
          this.selectedNatureBussiness = element.name
        }
      })
      this.selectedAnnualValue = this.preKYCDraftData.rangeOfAnnualIncome;
      this.annualIncomeList.forEach(element => {
        if (this.selectedAnnualValue === element.value) {
          this.selectedAnnualIncome = element.name
        }
      })
      //fill wealth
      this.selectedSourceFunds = this.preKYCDraftData.sourceOfFund;
      this.sourceFundsList.forEach(function(e){
        if(e.value==_this.selectedSourceFunds){
          e.selected = 1;
        }
      })
      this.otherSourceOfFunds = this.preKYCDraftData.sourceOfFundDescription;
      this.selectedSourceWealth = this.preKYCDraftData.sourceOfWealth;
      this.sourceWealthList.forEach(function(e){
        if(e.value==_this.selectedSourceWealth){
          e.selected = 1;
        }
      })
      this.otherSourceOfWealth = this.preKYCDraftData.sourceOfWealthDescription;
    }
  },
  computed:{
    kycLanguage: function(){
      return this.$store.getters.getLang;
    },
    kycStatus:function(){
      return this.$store.getters.getProfileData.userKYCStatus;
    },
    maxUploadSizeInMB:function(){
      return this.$store.getters.getMaxUploadSize.size;
    },
    fileSizeTooBig: function(){
      return this.proofFileSizeRes > (this.maxUploadSizeInMB * 1024 * 1024)
    },
    submissionFileSizeTooBig: function(){
      return this.subFileSizeRes > (this.maxUploadSizeInMB * 1024 * 1024)
    },
    otherSourceFunds: function() {
      return this.selectedSourceFunds == "Others"
    },
    otherSourceWealth: function() {
      return this.selectedSourceWealth == "Others"
    },
    checkContact: function () {
      return (this.inputFirstName && this.inputLastName && this.inputDateOfBirth && this.inputGender && this.selectedNationality)
    },
    checkAddress: function () {
      return (!this.isInputAddressEmpty && this.inputPostalCode && this.inputCity && this.selectedCountry && this.residenceUploadedSuccess && !this.fileSizeTooBig)
    },
    checkWealth: function () {
      return (this.selectedSourceFunds && this.selectedSourceWealth && this.submissionUploadedSuccess && !this.submissionFileSizeTooBig && this.validateOtherSourceOfFunds && this.validateOtherSourceOfWealth)
    },
    validateOtherSourceOfFunds:function(){
      if (this.otherSourceFunds) {
        if (this.otherSourceOfFunds) {
          return true
        }
        else{
          return false
        }
      }
      else{
        return true
      }
    },
    validateOtherSourceOfWealth:function(){
      if (this.otherSourceWealth) {
        if (this.otherSourceOfWealth) {
          return true
        }
        else{
          return false
        }
      }
      else{
        return true
      }
    },
    checkEmployment: function() {
      if(this.selectedEmStatus == "" || this.selectedAnnualIncome == "") {
        return false
      } else {
        if(this.checkEmploymentSubMenu == true) {
          if(this.selectedNatureBussiness != "") {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }
    },
    checkEmploymentSubMenu: function() {
      let checkSubMenu = this.employmentStatus.filter(dt => dt.name == this.selectedEmStatus)
      if(checkSubMenu.length > 0) {
        return checkSubMenu[0].submenu
      } else {
        return false
      }
    }
  },
  watch:{
    kycLanguage(){
      this.fetchEmploymentData();
      this.fetchNatureData();
      this.fetchAnnualIncome();
      this.fetchSourceFunds();
      this.fetchSourceWealth();
      this.dropOptions.dictDefaultMessage = this.translate('drop_file');
      // this.$refs.dropzone.forceUpdate();
    },
    inputAddress(dt) {
      if (dt && dt.length > 0) {
        this.isInputAddressEmpty = false
      } else {
        this.isInputAddressEmpty = true
      }
    },
    selectedCountry: function(after) {
      this.countryList.forEach(element => {
        if (after === element.name) {
          this.countryCode = element.code
        }
      })
    },
    selectedNationality: function(after) {
      this.nationalityList.forEach(element => {
        if (after === element.name) {
          this.nationalityCode = element.code
        }
      })
    },
    selectedEmStatus: function(after) {
      this.employmentStatus.forEach(element => {
        if (after === element.name) {
          this.selectedEmValue = element.value
          this.selectedEmSubmenu = element.submenu
        }
      })
    },
    selectedNatureBussiness: function(after) {
      this.natureBussinessList.forEach(element => {
        if (after === element.name) {
          this.selectedNatureValue = element.value
        }
      })
    },
    selectedAnnualIncome: function(after) {
      this.annualIncomeList.forEach(element => {
        if (after === element.name) {
          this.selectedAnnualValue = element.value
        }
      })
    }
  }
}
</script>
