<template>
  <div class="o-verification-process">
    <StartMenu :startVerify="changeToForm" v-if="active == 'start'"></StartMenu>
    <PreKYCForm v-if="active == 'form'"></PreKYCForm>
    <JumioFrame v-if="active == 'jumio'"></JumioFrame>
  </div>
</template>

<script>
import StartMenu from '@/components/verification/StartMenu.vue'
import PreKYCForm from '@/components/verification/PreKYCForm.vue'
import JumioFrame from '@/components/verification/JumioFrame.vue'

export default {
  name: 'VerificationProcess',
  components: {
    PreKYCForm,
    StartMenu,
    JumioFrame
  },
  data() {
    return {
      active: "start"
    }
  },
  created() {
    this.$eventHub.$on('formSubmitted', this.updateMe);
  },
  beforeDestroy(){
    this.$eventHub.$off('formSubmitted');
  },
  mounted() {

  },
  methods: {
    changeToForm() {
      this.checkKyc()
    },
    checkKyc() {
      if(this.profileData.body.addressVerificationStatus == 'APPROVED' || this.profileData.body.addressVerificationStatus == 'PENDING_APPROVAL') {
        if(this.profileData.body.userKYCStatus == 'VERIFIED' || this.profileData.body.userKYCStatus == 'PENDING_REVIEW') {
          window.location = 'dashboard/#/checker'
        } else {
          this.active = "jumio"
        }
      }else {
        this.active = "form"
      }
    },
    updateMe() {
      this.$http.get(this.createApiUrlChestNut('me')).then(me =>{
        this.$store.commit("setProfileData", me);
        setTimeout(() => {
          this.checkKyc()
        },100)
      })
    }
  },
  computed: {
    profileData: function(){
      return this.$store.getters.getProfileData;
    }
  }
}
</script>
