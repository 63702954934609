<template>
  <div class="c-prekyc">
    <div class="c-prekyc__start">
      <div class="c-prekyc__start">
        <img class="c-prekyc__start-logo" src="../../assets/images/old-alpha/verify-logo.png" alt="Verify Identity">
        <h2 class="c-prekyc__start-title" translate="verification_identity"><span class="c-prekyc__start-title_blue">Verify </span>Your Identity</h2>
        <p class="c-prekyc__start-desc">You will need to verify your identity before any transactions can be done<br />on Sparrow Platform. Please follow the steps below to complete the process.<br />The verification and approval process may take up to 3 days.</p>
        <button class="c-prekyc__start-button" @click="startVerify()">
          <span>{{"ver_start" | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    startVerify: Function
  },
  data() {
    return {

    }
  },
  mounted() {
    this.checkToken()
  },
  methods: {
    checkToken() {
      this.$http.get(this.createApiUrlChestNut('me')).then(response => {
        console.log(response)
        this.$store.commit("setProfileData", response);
      }, err=> {
        if(err.status == 401) {
          this.$store.commit('doLogin',{token:''})
          this.$router.push("/signin").catch(() => {});
        }
      })
    }
  }
}
</script>