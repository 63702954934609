<template>
  <div class="c-jumio-frame">
    <div class="c-jumio-frame__loading-text" v-if="preloader">
      Please wait..
      <div class="c-jumio-frame__loading">
        <i class="ball-pulse-small jumio"><div></div><div></div><div></div></i>
      </div>
    </div>
    <div class = "c-jumio-frame__container" v-else-if="!showSuccess">
      <iframe class="c-jumio-frame__iframe" v-bind:class="{'c-jumio-frame__iframe_success': iframeModify}" id="jumio-iframe" :src="kycRedirectUrl" allow="camera;fullscreen;accelerometer;gyroscope;magnetometer" frameborder="0" allowfullscreen></iframe>
      <div class="c-jumio-frame__trigger">
        <p class="c-jumio-frame__trigger-kyc"> <i v-on:click="showQuickGuide()">{{"kyc_tips_trigger" | translate}}</i></p>
      </div>
    </div>
    <div class = "c-jumio-frame__jumio-success-container" v-else>
      <div class="c-jumio-frame__kyc-container">
          <div class="c-jumio-frame__kyc-description">
            <div class="c-jumio-frame__content">
              <span>
                <img src="@/assets/images/jumio-success.png" class="c-jumio-frame__img" alt="Success">
              </span>
              <span class="c-jumio-frame__success-text" translate="jumio_success_title">Thank you</span>
            </div>
            <p translate="jumio_success_desc" class="c-jumio-frame__success-desc">
              Your verification is now being processed.
            </p>
          </div>
        </div>
      <button type="button" class="c-jumio-frame__next" @click = "redirectToDashboard()">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JumioFrame',
  data() {
    return {
      preloader: false,
      iframeModify: false,
      kycLanguage: "en",
      dataKyc: [],
      kycRedirectUrl: "",
      transactionReference: '',
      showSuccess:false
    }
  },
  created:function(){
    window.addEventListener('message', this.messageHandler, false);
  },
  mounted:function(){
    this.startVerification()
  },
  destroyed(){
    window.removeEventListener('message', this.messageHandler, false)
  },
  methods:{
    showQuickGuide:function(){
      this.$eventHub.$emit('show-quick-guide')
    },
    redirectToDashboard:function(){
      window.location = '/dashboard/#/checker'
    },
    startVerification: function () {
      this.preloader = true;
      this.kycLanguage = this.$store.getters.getLang
      this.showKYCIFrame()
    },
    messageHandler: function(msg) {
      if(msg.origin === location.origin && msg.data === 'success') {
        this.iframeModify = true;
        this.updateKycStatusToPending(this.transactionReference);
      }
    },
    updateKycStatusToPending: function (transactionReference) {
      this.$http.post(this.createApiUrlChestNut("kyc/jumio/set-pending"), {
        code: transactionReference
      }).then(() => {
        this.showSuccess = true;
      }, () => {
        this.$eventHub.$emit('show-alert', true, {title: 'Oops, something went wrong.', body: 'Please try again.'}, false)
      })
    },
    showKYCIFrame:function(){
      this.$http.get(this.createApiUrlChestNut("kyc/init/?locale="+this.kycLanguage)).then(response => {
        this.dataKyc = response.body.data;
        this.kycRedirectUrl = this.dataKyc.redirectUrl;
        this.transactionReference = this.dataKyc.transactionReference;
        this.preloader = false;
      },() => {
        this.$eventHub.$emit('show-alert', true, {title: 'Oops, something went wrong.', body: 'Please try again.'}, false)
      })
    },
  }
}
</script>
